import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Auth from 'aws-amplify';
import awsConfig from './config/awsConfig';
import dotenv from 'dotenv';

dotenv.config();

Auth.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
