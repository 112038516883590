import React, { createContext, useReducer } from 'react';
import reducer, { initialState } from '../reducer';

const stateContext = createContext({ state: {}, dispatch: () => {} });

// eslint-disable-next-line react/display-name
export const withState = Component => props => {
  const [state, dispatch] = useReducer(reducer, initialState, s => s);
  return (
    <stateContext.Provider value={{ state, dispatch }}>
      <Component {...props} />
    </stateContext.Provider>
  );
};

export default stateContext;
