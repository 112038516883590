import React, { useContext } from 'react';
import stateContext from '../contexts/stateContext';
import { userContext } from '../contexts/userContext';
import { CONVERSATION_STATUS, LOG_TYPES } from '../constants';
import ButtonFooter from '../components/ButtonFooter';
import TextInput from '../components/TextInput';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createMessage,
  updateConversation as updateConversationQuery,
} from '../graphql/mutations';
import { updateConversation as updateConversationAction } from '../actions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useConversationLog from '../hooks/useConversationLog';
import { updateCountEvent } from '../events';

const ConversationFooter = () => {
  const {
    state: { conversation },
    dispatch,
  } = useContext(stateContext);
  const { id, status, moderatorId, clientId } = conversation;
  const { user } = useContext(userContext);
  const { createLog } = useConversationLog(conversation);

  const sendMessage = content =>
    API.graphql(
      graphqlOperation(createMessage, {
        input: {
          content,
          conversationId: id,
          recipient: clientId,
        },
      })
    );

  const startConversation = () => {
    createLog(LOG_TYPES.ACTIVE);
    API.graphql(
      graphqlOperation(updateConversationQuery, {
        id,
        status: CONVERSATION_STATUS.ACTIVE,
        moderatorId: user.id,
      })
    ).then(({ data: { updateConversation } }) => {
      dispatch(updateConversationAction(updateConversation));
      window.dispatchEvent(updateCountEvent);
    });
  };

  return (
    <>
      {status === CONVERSATION_STATUS.OPEN && (
        <ButtonFooter
          action={startConversation}
          text="Wanneer je het gesprek start, verplaatst de chat zich naar actieve
      gesprekken en word je toegevoegd als gespreksleider"
          buttonLabel="start gesprek"
        />
      )}
      {status === CONVERSATION_STATUS.ACTIVE &&
        (moderatorId.id === user.id ? (
          <TextInput sendMessage={sendMessage} />
        ) : (
          <ButtonFooter
            text={`Dit is een meeleesgesprek van ${conversation.moderatorId.name}.`}
          />
        ))}
      {status === CONVERSATION_STATUS.CLOSED && (
        <ButtonFooter
          action={startConversation}
          text="Dit gesprek is gesloten. Let op: het heropenen van een gesloten gesprek is experimenteel."
          buttonLabel="heropenen"
        />
      )}
      {status === CONVERSATION_STATUS.ARCHIVED && (
        <Box bgcolor="grey.300" p={2}>
          <Typography variant="subtitle2" align="center">
            Dit gesprek is gearchiveerd.
          </Typography>
        </Box>
      )}
    </>
  );
};

ConversationFooter.propTypes = {};

export default ConversationFooter;
