import React, { useContext, useEffect, useRef, useState } from 'react';
import stateContext from '../contexts/stateContext';
import { API, graphqlOperation } from 'aws-amplify';
import { updateMessage } from '../graphql/mutations';
import { onCreateMessage } from '../graphql/subscriptions';
import { addMessage, updateMessageStatus } from '../actions';
import clusterMessagesByDay from '../utils/clusterMessagesByDay';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import parseDateString from '../utils/parseDateString';
import Message from '../components/Message';
import { getCopy, markFirstMessageOfSender, sortByDate } from '../utils';
import { userContext } from '../contexts/userContext';

const parseEvents = events =>
  events.map(event => ({
    ...event,
    type: 'event',
  }));

const parseMessages = messages =>
  messages.sort(sortByDate.ascending('createdAt')).map((message, index) => ({
    ...message,
    type: index === 0 ? 'first' : 'default',
  }));

const Messages = () => {
  const {
    state: {
      conversation: {
        id,
        subject,
        clientId,
        messages: { items: messages },
        conversationStatusLog: { items: events },
      },
    },
    dispatch,
  } = useContext(stateContext);

  console.log('MESSAGES', messages);
  const { user } = useContext(userContext);
  const [clusters, setClusters] = useState([]);
  const anchorRef = useRef();

  useEffect(() => {
    if (messages) {
      Promise.resolve(
        parseEvents(events)
          .concat(parseMessages(messages))
          .sort(sortByDate.ascending('createdAt'))
      )
        .then(clusterMessagesByDay)
        .then(setClusters)
        .finally(() => anchorRef.current.scrollIntoView());
    }

    const messageSubscription = API.graphql(
      graphqlOperation(onCreateMessage, { conversationId: id })
    ).subscribe({
      next: ({
        value: {
          data: { onCreateMessage },
        },
      }) => {
        dispatch(addMessage(onCreateMessage));
        anchorRef.current.scrollIntoView();
      },
    });

    return () => {
      messageSubscription.unsubscribe();
    };
  }, [events, dispatch, messages, id, user.id]);

  useEffect(() => {
    const setMessageRead = async (chatId, messageCreatedAt) => {
      const messageStatus = { read: true, received: true };
      try {
        const response = await API.graphql(
          graphqlOperation(updateMessage, {
            conversationId: chatId,
            createdAt: messageCreatedAt,
            status: messageStatus,
          })
        );
        console.log('update message response', response);
        dispatch(updateMessageStatus(response));
      } catch (err) {
        console.error('Failed updating message', err);
      }
    };

    for (const message of messages) {
      if (message.status !== null && message.status !== undefined) {
        if (
          message.status.read === false &&
          message.senderUser.id === clientId
        ) {
          console.log('MESSAGE NOT YET READ', message, id);
          setMessageRead(id, message.createdAt);
        }
      }
    }
  }, [dispatch, messages, id, clientId]);

  return (
    <Box flexGrow={2} px={2} pt={2} style={{ overflowY: 'scroll' }}>
      {clusters.length === 0 && (
        <Box key="cluster-0" display="flex" flexDirection="column">
          <Box p={1} bgcolor="grey.200" display="inline-block" mx="auto">
            <Typography variant="subtitle2" color="textSecondary">
              Er zijn geen berichten in deze conversatie
            </Typography>
          </Box>
        </Box>
      )}
      {clusters.map((cluster, index) => (
        <Box key={`cluster-${index}`} display="flex" flexDirection="column">
          <Box p={1} bgcolor="grey.200" display="inline-block" mx="auto">
            <Typography variant="subtitle2" color="textSecondary">
              {parseDateString(cluster[0].createdAt)}
            </Typography>
          </Box>
          {cluster.map((message, index) =>
            message.type === 'event' ? (
              <Box
                key={`event-${index}`}
                mx="auto"
                bgcolor="grey.200"
                display="inline-block"
                my={1}
                py={0.5}
                px={2}
              >
                <Typography variant="overline" color="textSecondary">
                  {getCopy(`eventBy.${message.status}`)}
                  {message.name !== 'system' && message.name}
                </Typography>
              </Box>
            ) : (
              <Message
                key={message.createdAt}
                subject={subject}
                incoming={message.senderUser.id === clientId}
                hasEarmark={
                  message.type !== 'event' &&
                  markFirstMessageOfSender(message, cluster[index - 1])
                }
                {...message}
              />
            )
          )}
        </Box>
      ))}
      <div ref={anchorRef} />
    </Box>
  );
};

export default Messages;
